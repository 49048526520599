import hadi from "./teammobile/hadi.png";
import hassan from "./teammobile/hassan.png";
import hashem from "./teammobile/hashem.png";
import imad from "./teammobile/imad.png";
import noureden from "./teammobile/noureden.png";

export const members = [
  {
    id: 1,
    isClicked: true,
    upperHeight: 0,
    bottomHeight: 100,
    name: "Hadi Saab",
    job: "Solutions Engineer",
    image: hadi,
    zIndex: "2",
    top: "20%",
    left: "0",
    email: "saabHadi285@gmail.com",
    phoneNumber: "+96171112521",
    linkedIn: "Hadi saab",
    linkedInURL: "https://www.linkedin.com/in/hadi-saab-81952421b",
    experience: "2+",
  },
  {
    id: 2,
    isClicked: false,
    upperHeight: 50,
    bottomHeight: 50,
    name: "Imad Saad",
    job: "Software Engineer",
    image: imad,
    zIndex: "3",
    top: "20%",
    left: "49%",
    email: "imad.alhaj.saad@gmail.com",
    phoneNumber: "+96181360613",
    linkedIn: "Imad Saad",
    linkedInURL: "https://www.linkedin.com/in/imad-saad-37b831208/",
    experience: "2+",
  },
  {
    id: 3,
    isClicked: false,
    upperHeight: 100,
    bottomHeight: 0,
    name: "Ali Hashem",
    job: "Software Developer",
    image: hashem,
    zIndex: "4",
    top: "45%",
    left: "0",
    email: "alih61175@gmail.com",
    phoneNumber: "+96171537034",
    linkedIn: "Ali hashem",
    linkedInURL: "https://www.linkedin.com/in/ali-hashem-96798521b",
    experience: "1+",
  },

  // {
  //   id: 5,
  //   isClicked: false,
  //   upperHeight: 50,
  //   bottomHeight: 50,
  //   name: "Hadi Nourdine",
  //   job: "Backend Engineer",
  //   image: noureden,
  //   zIndex: "6",
  //   top: "70%",
  //   left: "0",
  //   email: "hadi.noureddine.9@gmail.com",
  //   phoneNumber: "+96171422892",
  //   linkedIn: "Hadi Nourdine",
  //   linkedInURL: "https://www.linkedin.com/in/hadi-nour-eddine",
  //   experience: "2+",
  // },
  {
    id: 6,
    isClicked: false,
    upperHeight: 100,
    bottomHeight: 0,
    name: "Hassan Awwad",
    job: "Software Developer",
    image: hassan,
    zIndex: "7",
    top: "70%",
    left: "49%",
    email: "hasannawadd@gmail.com",
    phoneNumber: "+96103418485",
    linkedIn: "Hassan awwad",
    linkedInURL: "https://www.linkedin.com/in/hasan-awwad-1b45aa249/",
    experience: "1+",
  },
  {
    id: 4,
    isClicked: false,
    upperHeight: 0,
    bottomHeight: 100,
    name: "Bassel Kassem",
    job: "Programmatic Executive",
    image: hadi,
    zIndex: "5",
    top: "45%",
    left: "49%",
    email: "Bassel.Kassem@gmail.com",
    phoneNumber: "+96171980367",
    linkedIn: "Bassel Kassem",
    linkedInURL: "https://www.linkedin.com/in/bassel-kassem-6706161b5/",
    experience: "2+",
  },
];
