import React from "react";
import { Light, Light2, Stars } from "./styles";
import stars from "../../../../static/stars.png";

export default function Background() {
  return (
    <>
      {/* <Stars src={stars} /> */}
      {/* <Light />
      <Light2 /> */}
    </>
  );
}
